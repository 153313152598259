import React from "react"
import Image from "safe-gatsby-image-plugin"

const NonStretchedImage = props => {
  let normalizedProps = props
  const fluidProp = props.data.localFile?.childImageSharp?.fluid
  if (fluidProp && fluidProp.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: "calc(100% - 120px)",
        maxHeight: window.innerHeight - 50,
        height: window.innerHeight - 50,
        width: "auto",
        margin: "50px auto 0", // Used to center the image
      },
    }
  }

  return <Image {...normalizedProps} />
}
export default NonStretchedImage
